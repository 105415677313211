import "./FAQ.css";

function FAQ(props) {
  return (
    <div className="wrapper">
      <div className="textblock">
        <div className="row-illustration">
          <p className="textblock__text">
            <p className="textblock__title">{props.title}</p>
            <br></br>
            <p className="textblock__question">{props.q1}</p>
            <p className="textblock__answer">{props.a1}</p>
            <br></br>
            <p className="textblock__question">{props.q2}</p>
            <p className="textblock__answer">{props.a2}</p>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
