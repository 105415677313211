import "./TextBlock.css";
import { ReactComponent as Illustration } from "../svg/onb7.svg";

function TextBlock(props) {
  return (
    <div className="wrapper">
      <div className="textblock">
        <div className="row-illustration">
          <div className="textblock__content">
            <p className="textblock__title">{props.title}</p>
            <p className="textblock__text">{props.t1}</p>
            <p className="textblock__text">{props.t2}</p>
            <p className="textblock__text">{props.t3}</p>
          </div>
          <div className="illustration">
            <Illustration />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextBlock;