import { BrowserRouter, Routes, Route } from "react-router-dom";
import Donate from "./pages/Donate.js";
import Home from "./pages/Home.js";
import Download from "./pages/Download.js";
import Delete from "./pages/Delete.js";
import Privacy from "./pages/Privacy.js";
import Terms from "./pages/Terms.js";

function App() {
  return (
    <BrowserRouter>
      <Routes>
    
        <Route path="/" element={<Home />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/download" element={<Download />} />
          <Route path="/delete" element={<Delete />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
      
      </Routes>
    </BrowserRouter>
  );
}

export default App;
