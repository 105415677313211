import "./NewFooter.css";

function NewFooter() {
  return (
    <div className="wrapper">
        <div className="between">
    <div className="newfooter">
      <a href="/privacy"  rel="noreferrer" className="newfooter__text">Informativa sulla privacy</a>
      <a href="/terms"  rel="noreferrer" className="newfooter__text">Termini e condizioni</a>
      <a href="/delete" target="_blank" className="newfooter__text">Elimina account</a>
      <a href="https://www.instagram.com/essence.app" target="_blank" rel="noreferrer" className="newfooter__text">© 2025 Essence</a>
    </div></div></div>
  );
}

export default NewFooter;
