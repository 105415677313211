import "./TextBlockPrivacy.css";


function TextBlockPrivacy() {

  
  return (
    <div className="textblockprivacy">
      <p className="textblockprivacy__h1" style={{fontSize: '30px', marginBottom: '3.5rem' }}>Privacy policy di Essence</p>
      <p className="textblockprivacy__h4">&nbsp;</p>

      <p className="textblockprivacy__h4">Essence raccoglie alcuni Dati Personali dei propri Utenti.</p>
      <p className="textblockprivacy__h4">Questo documento può essere stampato utilizzando il comando di stampa presente nelle impostazioni di qualsiasi browser.</p>
      <hr className="separator" />
      <p className="textblockprivacy__h2">Titolare del trattamento dei dati</p>
      <p className="textblockprivacy__h4">Andrea Tempestini - Via S. G. Bosco 10, 21013, Gallarate (VA), Italia</p>
      <p className="textblockprivacy__h4">Indirizzo email del Titolare: andreatempestini@pec.it</p>
      <hr className="separator" />


      <p className="textblockprivacy__h2">Tipologie di Dati raccolti</p>
      <p className="textblockprivacy__h4">
        Fra i Dati Personali raccolti da Essence, in modo autonomo o tramite terze parti, ci sono: Dati di utilizzo;
        informazioni sul dispositivo; regione geografica; numero di Utenti; numero di sessioni; durata della sessione;
        acquisti in-app; apertura dell'Applicazione; aggiornamenti dell'Applicazione; lanci; sistemi operativi;
        Strumenti di Tracciamento; nome; cognome; email; nazione; Dati comunicati durante l'utilizzo del servizio;
        log del dispositivo; informazioni sull'app; Permesso Camera; Permesso Localizzazione precisa (non continua);
        Permesso Memoria; Permesso Galleria Fotografica; diagnosi; interazione con i prodotti; cronologia acquisti; ID Utente.
      </p>
      <p className="textblockprivacy__h4">
        Dettagli completi su ciascuna tipologia di Dati Personali raccolti sono forniti nelle sezioni dedicate di questa privacy policy
        o mediante specifici testi informativi visualizzati prima della raccolta dei Dati stessi.
        I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di Essence.
        Se non diversamente specificato, tutti i Dati richiesti da Essence sono obbligatori. Se l'Utente rifiuta di comunicarli, potrebbe essere impossibile per
        Essence fornire il Servizio. Nei casi in cui Essence indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati,
        senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.
        Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori sono incoraggiati a contattare il Titolare.
        L'eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di Essence o dei titolari dei servizi terzi utilizzati
        da Essence ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento.
      </p>
      <p className="textblockprivacy__h4">
        L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante Essence.
      </p>

      <hr className="separator" />

      <p className="textblockprivacy__h2">Modalità e luogo del trattamento dei Dati raccolti</p>
      <p className="textblockprivacy__h3">
        Modalità di trattamento
      </p>
      <p className="textblockprivacy__h4">
        Il Titolare adotta le opportune misure di sicurezza volte ad impedire l'accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.
        Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell'organizzazione di Essence (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L'elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.
      </p>
      <p className="textblockprivacy__h3">
        Luogo
      </p>
      <p className="textblockprivacy__h4">
        I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.
        I Dati Personali dell'Utente potrebbero essere trasferiti in un paese diverso da quello in cui l'Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l'Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.
      </p>
      <p className="textblockprivacy__h3">
        Periodo di conservazione
      </p>
      <p className="textblockprivacy__h4">
        Se non diversamente indicato in questo documento, i Dati Personali sono trattati e conservati per il tempo richiesto dalla finalità per la quale sono stati raccolti e potrebbero essere conservati per un periodo più lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti.
      </p>

      <hr className="separator" />

      <p className="textblockprivacy__h2">Finalità del Trattamento dei Dati raccolti</p>
      <p className="textblockprivacy__h4">
        I Dati dell'Utente sono raccolti per consentire al Titolare di fornire il Servizio, adempiere agli obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed interessi (o quelli di Utenti o di terze parti), individuare eventuali attività dolose o fraudolente, nonché per le seguenti finalità: Statistica, Registrazione ed autenticazione, Visualizzazione di contenuti da piattaforme esterne, Gestione contatti e invio di messaggi, Beta Testing, Servizi di piattaforma e hosting e Permessi per l'accesso ai Dati Personali presenti sulla periferica dell'Utente.
      </p>
      <p className="textblockprivacy__h4">
        Per ottenere informazioni dettagliate sulle finalità del trattamento e sui Dati Personali trattati per ciascuna finalità, l'Utente può fare riferimento alla sezione "Dettagli sul trattamento dei Dati Personali".
      </p>
      <hr className="separator" />

      <p className="textblockprivacy__h2">Permessi per l'accesso ai Dati Personali presenti sulla periferica dell'Utente</p>
      <p className="textblockprivacy__h4">
        A seconda della specifica periferica utilizzata dall'Utente, Essence potrebbe richiedere alcuni permessi per accedere ai Dati dell'Utente presenti sulla periferica stessa, secondo quanto descritto in seguito.
      </p>
      <p className="textblockprivacy__h4">
        Tali permessi devono essere forniti dall'Utente prima che qualunque informazione possa essere trattata. In seguito al rilascio, il permesso può essere revocato dall'Utente in qualunque momento. Al fine di revocare i permessi, l'Utente può utilizzare le impostazioni di sistema o contattare il Proprietario ai recapiti indicati nel presente documento.
        La procedura per il controllo dei permessi può variare in funzione della periferica e del software utilizzati dall'Utente.
      </p>
      <p className="textblockprivacy__h4">
        Si prega di considerare che la revoca di uno o più permessi può comportare delle conseguenze sul corretto funzionamento di Essence.
      </p>
      <p className="textblockprivacy__h4">
        Nel caso in cui l'Utente conceda i permessi indicati in seguito, i relativi Dati Personali potranno essere oggetto di trattamento (accesso, modifica o rimozione) da parte di Essence.
      </p>

      <p className="textblockprivacy__h3">
        Permesso Camera
      </p>
      <p className="textblockprivacy__h4">
        Utilizzato per accedere alla videocamera o acquisire immagini e video dalla periferica.
      </p>

      <p className="textblockprivacy__h3">
        Permesso Galleria Fotografica
      </p>
      <p className="textblockprivacy__h4">
        Consente l'accesso alla Galleria Fotografica dell'Utente.
      </p>

      <p className="textblockprivacy__h3">
        Permesso Localizzazione precisa (non continua)
      </p>
      <p className="textblockprivacy__h4">
        Utilizzato per accedere alla posizione precisa della periferica dell'Utente. Essence può raccogliere, usare e condividere la posizione dell'Utente al fine di fornire servizi basati sulla localizzazione.
        La posizione geografica dell'Utente è determinata in modo non continuativo. Tale fatto implica che Essence non può localizzare la posizione esatta dell'Utente in modo continuativo.
      </p>

      <p className="textblockprivacy__h3">
        Permesso Memoria
      </p>
      <p className="textblockprivacy__h4">
        Utilizzato per accedere a dispositivi di memorizzazione esterni condivisi e comprensivo della possibilità di leggere e aggiungere su di essi qualunque elemento.
      </p>

      <hr className="separator" />

      <p className="textblockprivacy__h2" style={{fontSize: "24px"}}>Dettagli sul trattamento dei Dati Personali</p>
      <p className="textblockprivacy__h4">
        I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:      </p>
      <p className="textblockprivacy__h2" style={{paddingTop: '3rem',   }}>Beta Testing</p>
      <p className="textblockprivacy__h4">
        Questo tipo di servizio permette di gestire l'accesso, anche parziale, degli Utenti a Essence allo scopo di testare specifiche funzioni o l'Applicazione nel suo insieme.
        Il fornitore del servizio potrebbe raccogliere dati relativi a guasti e statistiche relative all'uso di Essence da parte dell'Utente in forma tale da permettere la sua identificazione.
      </p>
      <p className="textblockprivacy__h3">Google Play Beta Testing (Google Ireland Limited)</p>
      <p className="textblockprivacy__h4">
        Google Play Beta Testing è un servizio di beta testing offerto da Google Ireland Limited.
        Dati Personali trattati: cognome; Dati comunicati durante l'utilizzo del servizio; Dati di utilizzo; email; informazioni sul dispositivo; log del dispositivo; nazione; nome; Strumenti di Tracciamento; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.
        Luogo del trattamento: Irlanda – Privacy Policy.</p>
      <p className="textblockprivacy__h3">TestFlight (Apple Inc.)</p>

      <p className="textblockprivacy__h4">
        TestFlight è un servizio di beta testing offerto da Apple Inc.
        Dati Personali trattati: cognome; Dati comunicati durante l'utilizzo del servizio; Dati di utilizzo; email; informazioni sul dispositivo; informazioni sull'app; nome; Strumenti di Tracciamento; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.
        Luogo del trattamento: Stati Uniti – Privacy Policy.</p>
      <p className="textblockprivacy__h2" style={{ paddingTop: '4rem', }}>Gestione contatti e invio di messaggi</p>      <p className="textblockprivacy__h4">
        Questo tipo di servizi consente di gestire un database di contatti email, contatti telefonici o contatti di qualunque altro tipo, utilizzati per comunicare con l'Utente.
        Questi servizi potrebbero inoltre consentire di raccogliere dati relativi alla data e all'ora di visualizzazione dei messaggi da parte dell'Utente, così come all'interazione dell'Utente con essi, come le informazioni sui click sui collegamenti inseriti nei messaggi.
      </p>

      <p className="textblockprivacy__h3">Notifiche di Firebase (Google Ireland Limited)</p>
      <p className="textblockprivacy__h4">
        Firebase Notifications è un servizio di messagistica fornito da Google Ireland Limited. Firebase Notifications può essere integrato con Firebase Analytics per inviare messaggi ad Utenti sulla base dei risultati dell'attività di analisi e per tracciare eventi di utilizzo e conversione da parte degli Utenti.

        Dati Personali trattati: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.

        Luogo del trattamento: Irlanda – Privacy Policy.
      </p>

      <p className="textblockprivacy__h3">Firebase Cloud Messaging (Google Ireland Limited)</p>
      <p className="textblockprivacy__h4">
        Firebase Cloud Messaging è un servizio di messagistica fornito da Google Ireland Limited. Firebase Cloud Messaging permette al Titolare di inviare messaggi e notifiche ad Utenti su piattaforme quali Android, iOS e sul web. I messaggi possono essere inviati a singoli dispositivi, a gruppi di dispositivi, o sulla base di argomenti o specifici segmenti di Utenti.

        Dati Personali trattati: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.

        Luogo del trattamento: Irlanda – Privacy Policy.
      </p>

      <p className="textblockprivacy__h2" style={{ paddingTop: '4rem', }}>Permessi per l'accesso ai Dati Personali presenti sulla periferica dell'Utente</p>   
<p className="textblockprivacy__h4">
  Essence richiede il rilascio di alcuni permessi da parte dell'Utente che, in questo modo, autorizza l'accesso ai Dati presenti sulla propria periferica, nei termini di seguito indicati.
</p>

<p className="textblockprivacy__h4">Permessi per l'accesso ai Dati Personali presenti sulla periferica dell'Utente (Essence)</p>
<p className="textblockprivacy__h4">
  Essence richiede il rilascio di alcuni permessi da parte dell'Utente che, in questo modo, autorizza l'accesso ai Dati presenti sulla propria periferica, come qui riepilogato nonché nei termini indicati in questo documento.
</p>
<p className="textblockprivacy__h4">
  Dati Personali trattati: Permesso Camera; Permesso Galleria Fotografica; Permesso Localizzazione precisa (non continua); Permesso Memoria.
</p>

<p className="textblockprivacy__h2" style={{ paddingTop: '4rem', }}>Registrazione ed autenticazione</p>
<p className="textblockprivacy__h4">
  Con la registrazione o l'autenticazione l'Utente consente a Essence di identificarlo e di dargli accesso a servizi dedicati.
  A seconda di quanto indicato di seguito, i servizi di registrazione e di autenticazione potrebbero essere forniti con l'ausilio di terze parti. Qualora questo avvenga, Essence potrà accedere ad alcuni Dati conservati dal servizio terzo usato per la registrazione o l'identificazione.
  Alcuni dei servizi di seguito indicati potrebbero raccogliere Dati Personali anche per fini di targeting e profilazione; per saperne di più, si prega di fare riferimento alla descrizione di ciascun servizio.
</p>

<p className="textblockprivacy__h3">Google OAuth (Google Ireland Limited)</p>
<p className="textblockprivacy__h4">
  Google OAuth è un servizio di registrazione ed autenticazione fornito da Google Ireland Limited e collegato al network Google.

  Dati Personali trattati: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.

  Luogo del trattamento: Irlanda – Privacy Policy.
</p>


<p className="textblockprivacy__h2" style={{ paddingTop: '4rem', }}>Servizi di piattaforma e hosting</p>
<p className="textblockprivacy__h4">
  Questi servizi hanno lo scopo di ospitare e far funzionare componenti chiave di Essence, rendendo possibile l'erogazione di Essence da un'unica piattaforma. Queste piattaforme forniscono al Titolare un'ampia gamma di strumenti quali, ad esempio, strumenti analitici, per la gestione della registrazione degli utenti, per la gestione dei commenti e del database, per il commercio elettronico, per l'elaborazione dei pagamenti etc. L'uso di tali strumenti comporta la raccolta e il trattamento di Dati Personali.
  Alcuni di questi servizi funzionano attraverso server dislocati geograficamente in luoghi differenti, rendendo difficile la determinazione del luogo esatto in cui vengono conservati i Dati Personali.
</p>

<p className="textblockprivacy__h3">Google Play Store (Google Ireland Limited)</p>
<p className="textblockprivacy__h4">
  Essence è distribuita su Google Play Store, una piattaforma per la distribuzione di applicazioni mobili, fornita da Google Ireland Limited.

  In virtù del fatto di essere distribuita attraverso questo app store, Google raccoglie i dati di utilizzo e diagnostica e condivide informazioni aggregate con il Titolare. Molte di queste informazioni sono elaborate sulla base di una dichiarazione di opt-in.

  Gli Utenti possono disabilitare questa funzione di analisi direttamente attraverso le impostazioni del proprio dispositivo. Ulteriori informazioni su come gestire le impostazioni di analisi sono disponibili su questa pagina.

  Dati Personali trattati: Dati di utilizzo.

  Luogo del trattamento: Irlanda – Privacy Policy.
</p>

<p className="textblockprivacy__h3">App Store Connect (Apple Inc.)</p>
<p className="textblockprivacy__h4">
  Essence è distribuita sull'App Store di Apple, una piattaforma per la distribuzione di applicazioni mobili, fornita da Apple Inc.

  App Store Connect permette al Titolare di gestire Essence sull'App Store di Apple. A seconda della configurazione, App Store Connect fornisce al Titolare dati statistici sul coinvolgimento degli utenti e sulla scoperta di applicazioni, sulle campagne di marketing, sulle vendite, sugli acquisti in-app e sui pagamenti per misurare le prestazioni di Essence. App Store Connect raccoglie tali dati solo dagli Utenti che hanno accettato di condividerli con il Titolare. Gli Utenti possono trovare maggiori informazioni su come fare opt-out tramite le impostazioni del loro dispositivo qui.

  Dati Personali trattati: cronologia acquisti; Dati di utilizzo; diagnosi; ID Utente; interazione con i prodotti; Strumenti di Tracciamento.

  Luogo del trattamento: Stati Uniti – Privacy Policy.
</p>


<p className="textblockprivacy__h2" style={{ paddingTop: '4rem', }}>Statistica</p>
<p className="textblockprivacy__h4">
  I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell'Utente.
</p>

<p className="textblockprivacy__h3">Google Analytics for Firebase (Google Ireland Limited)</p>
<p className="textblockprivacy__h4">
  Google Analytics per Firebase, o Firebase Analytics, è un servizio di analisi fornito da Google Ireland Limited.
  Per una comprensione dell'utilizzo dei dati da parte di Google, si prega di consultare le norme per i partner di Google.

  Firebase Analytics potrebbe condividere Dati con altri servizi forniti da Firebase tra cui, ad esempio, Crash Reporting, Authentication, Remote Config o Notifications. L'utente può consultare questa privacy policy per avere una descrizione dettagliata degli altri strumenti usati dal Titolare.

  Per permettere il funzionamento di Firebase Analytics, Essence usa alcuni identificatori per periferiche mobili ovvero tecnologie simili ai cookie.

  L'Utente può effettuare l'opt-out da alcune funzioni di Firebase mediante le impostazioni del proprio dispositivo mobile. Ad esempio, può modificare le impostazioni sulla pubblicità disponibili sul proprio telefonino, oppure seguire le istruzioni applicabili a Firebase eventualmente presenti all'interno di questa privacy policy.

  Dati Personali trattati: acquisti in-app; aggiornamenti dell'Applicazione; apertura dell'Applicazione; Dati di utilizzo; durata della sessione; informazioni sul dispositivo; lanci; numero di sessioni; numero di Utenti; regione geografica; sistemi operativi.

  Luogo del trattamento: Irlanda – Privacy Policy.
</p>


<p className="textblockprivacy__h2" style={{ paddingTop: '4rem', }}>Visualizzazione di contenuti da piattaforme esterne</p>
<p className="textblockprivacy__h4">
  Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di Essence e di interagire con essi.
  Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico web relativo alle pagine dove il servizio è installato, anche quando gli utenti non lo utilizzano.
</p>

<p className="textblockprivacy__h3">Google Fonts (Google Ireland Limited)</p>
<p className="textblockprivacy__h4">
  Google Fonts è un servizio di visualizzazione di stili di carattere gestito da Google Ireland Limited che permette a Essence di integrare tali contenuti all'interno delle proprie pagine.

  Dati Personali trattati: Dati di utilizzo; Strumenti di Tracciamento.

  Luogo del trattamento: Irlanda – Privacy Policy.
</p>

<hr className="separator" />
<p className="textblockprivacy__h2">Ulteriori informazioni sul trattamento dei Dati Personali</p>
<p className="textblockprivacy__h3">Notifiche push </p>
<p className="textblockprivacy__h4">

Essence può inviare notifiche push all'Utente per raggiungere gli scopi descritti nella presente privacy policy.

Nella maggior parte dei casi, gli Utenti possono scegliere di non ricevere notifiche push consultando le impostazioni del proprio dispositivo, come le impostazioni di notifica per i telefoni cellulari, e modificando quindi tali impostazioni per Essence, per alcune o per tutte le applicazioni presenti sul dispositivo in questione.
Gli Utenti devono essere consapevoli che la disabilitazione delle notifiche push può influire negativamente sull'utilizzo di Essence.
</p>

<hr className="separator" />
<p className="textblockprivacy__h2"> Ulteriori informazioni per gli utenti </p>

<p className="textblockprivacy__h3">Base giuridica del trattamento</p>
<p className="textblockprivacy__h4">
Il Titolare tratta Dati Personali relativi all'Utente in caso sussista una delle seguenti condizioni:
</p>

<p className="textblockprivacy__h4" style={{marginLeft: '20px'}}>
• l'Utente ha prestato il consenso per una o più finalità specifiche.<br />
• il trattamento è necessario all'esecuzione di un contratto con l'Utente e/o all'esecuzione di misure precontrattuali;<br />
• il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;<br />
• il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;<br />
• il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.
</p>

<p className="textblockprivacy__h4">
È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.
</p>
<hr className="separator" />

<p className="textblockprivacy__h3" style={{ paddingTop: '0rem' }}>Ulteriori informazioni sul tempo di conservazione</p>
<p className="textblockprivacy__h4">
Se non diversamente indicato in questo documento, i Dati Personali sono trattati e conservati per il tempo richiesto dalla finalità per la quale sono stati raccolti e potrebbero essere conservati per un periodo più lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti.
</p>

<p className="textblockprivacy__h4">
Pertanto:
</p>

<p className="textblockprivacy__h4" style={{marginLeft: '20px'}}>
• I Dati Personali raccolti per scopi collegati all'esecuzione di un contratto tra il Titolare e l'Utente saranno trattenuti sino a quando sia completata l'esecuzione di tale contratto.<br />
• I Dati Personali raccolti per finalità riconducibili all'interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L'Utente può ottenere ulteriori informazioni in merito all'interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.
</p>

<p className="textblockprivacy__h4">
Quando il trattamento è basato sul consenso dell'Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo per adempiere ad un obbligo di legge o per ordine di un'autorità.
</p>

<p className="textblockprivacy__h4">
Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.
</p>

<hr className="separator" />
<p className="textblockprivacy__h3" style={{ paddingTop: '0rem' }}>Diritti dell'Utente sulla base del Regolamento Generale sulla Protezione dei Dati (GDPR)</p>
<p className="textblockprivacy__h4">
Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.
</p>

<p className="textblockprivacy__h4">
In particolare, nei limiti previsti dalla legge, l'Utente ha il diritto di:
</p>

<p className="textblockprivacy__h4" style={{marginLeft: '20px'}}>
• revocare il consenso in ogni momento. L'Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso.<br />
• opporsi al trattamento dei propri Dati. L'Utente può opporsi al trattamento dei propri Dati quando esso avviene in virtù di una base giuridica diversa dal consenso.<br />
• accedere ai propri Dati. L'Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.<br />
• verificare e chiedere la rettificazione. L'Utente può verificare la correttezza dei propri Dati e richiederne l'aggiornamento o la correzione.<br />
• ottenere la limitazione del trattamento. L'Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.<br />
• ottenere la cancellazione o rimozione dei propri Dati Personali. L'Utente può richiedere la cancellazione dei propri Dati da parte del Titolare.<br />
• ricevere i propri Dati o farli trasferire ad altro titolare. L'Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare.<br />
• proporre reclamo. L'Utente può proporre un reclamo all'autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.
</p>

<p className="textblockprivacy__h4">
Gli Utenti hanno diritto di ottenere informazioni in merito alla base giuridica per il trasferimento di Dati all'estero incluso verso qualsiasi organizzazione internazionale regolata dal diritto internazionale o costituita da due o più paesi, come ad esempio l'ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i loro Dati.
</p>

<p className="textblockprivacy__h3">Dettagli sul diritto di opposizione</p>
<p className="textblockprivacy__h4">
Quando i Dati Personali sono trattati nell'interesse pubblico, nell'esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.
</p>

<p className="textblockprivacy__h4">
Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento in qualsiasi momento, gratuitamente e senza fornire alcuna motivazione. Qualora gli Utenti si oppongano al trattamento per finalità di marketing diretto, i Dati Personali non sono più oggetto di trattamento per tali finalità. Per scoprire se il Titolare tratti Dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.
</p>

<p className="textblockprivacy__h3">Come esercitare i diritti</p>
<p className="textblockprivacy__h4">
Eventuali richieste di esercizio dei diritti dell'Utente possono essere indirizzate al Titolare attraverso i recapiti forniti in questo documento. La richiesta è gratuita e il Titolare risponderà nel più breve tempo possibile, in ogni caso entro un mese, fornendo all'Utente tutte le informazioni previste dalla legge. Eventuali rettifiche, cancellazioni o limitazioni del trattamento saranno comunicate dal Titolare a ciascuno dei destinatari, se esistenti, a cui sono stati trasmessi i Dati Personali, salvo che ciò si riveli impossibile o implichi uno sforzo sproporzionato. Il Titolare comunica all'Utente tali destinatari qualora egli lo richieda.
</p>

<hr className="separator" />

<p className="textblockprivacy__h2">Ulteriori informazioni sul trattamento</p>
<p className="textblockprivacy__h3">Difesa in giudizio</p>
<p className="textblockprivacy__h4">
I Dati Personali dell'Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di Essence o dei Servizi connessi da parte dell'Utente.
L'Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.
</p>

<p className="textblockprivacy__h3">Informative specifiche</p>
<p className="textblockprivacy__h4">
Su richiesta dell'Utente, in aggiunta alle informazioni contenute in questa privacy policy, Essence potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.
</p>

<p className="textblockprivacy__h3">Log di sistema e manutenzione</p>
<p className="textblockprivacy__h4">
Per necessità legate al funzionamento ed alla manutenzione, Essence e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l'indirizzo IP Utente.
</p>

<p className="textblockprivacy__h3">Informazioni non contenute in questa policy</p>
<p className="textblockprivacy__h4">
Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.
</p>

<p className="textblockprivacy__h3">Modifiche a questa privacy policy</p>
<p className="textblockprivacy__h4">
Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su Essence nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso. Si prega dunque di consultare con frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.
</p>

<p className="textblockprivacy__h4">
Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell'Utente, se necessario.
</p>

<hr className="separator" />


<p className="textblockprivacy__h2" style={{fontSize: "24px"}}>Definizioni e riferimenti legali</p>
<p className="textblockprivacy__h3">Dati Personali (o Dati)</p>
<p className="textblockprivacy__h4">
Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.
</p>

<p className="textblockprivacy__h3">Dati di Utilizzo</p>
<p className="textblockprivacy__h4">
Sono le informazioni raccolte automaticamente attraverso Essence (anche da applicazioni di parti terze integrate in Essence), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall'Utente che si connette con Essence, gli indirizzi in notazione URI (Uniform Resource Identifier), l'orario della richiesta, il metodo utilizzato nell'inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all'itinerario seguito all'interno dell'Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all'ambiente informatico dell'Utente.
</p>

<p className="textblockprivacy__h3">Utente</p>
<p className="textblockprivacy__h4">
L'individuo che utilizza Essence che, salvo ove diversamente specificato, coincide con l'Interessato.
</p>

<p className="textblockprivacy__h3">Interessato</p>
<p className="textblockprivacy__h4">
La persona fisica cui si riferiscono i Dati Personali.
</p>

<p className="textblockprivacy__h3">Responsabile del Trattamento (o Responsabile)</p>
<p className="textblockprivacy__h4">
La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.
</p>

<p className="textblockprivacy__h3">Titolare del Trattamento (o Titolare)</p>
<p className="textblockprivacy__h4">
La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di Essence. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di Essence.
</p>

<p className="textblockprivacy__h3">Essence (o questa Applicazione)</p>
<p className="textblockprivacy__h4">
Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.
</p>

<p className="textblockprivacy__h3">Servizio</p>
<p className="textblockprivacy__h4">
Il Servizio fornito da Essence così come definito nei relativi termini (se presenti) su questo sito/applicazione.
</p>

<p className="textblockprivacy__h3">Unione Europea (o UE)</p>
<p className="textblockprivacy__h4">
Salvo ove diversamente specificato, ogni riferimento all'Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell'Unione Europea e dello Spazio Economico Europeo.
</p>

<p className="textblockprivacy__h3">Cookie</p>
<p className="textblockprivacy__h4">
I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno del browser dell'Utente.
</p>

<p className="textblockprivacy__h3">Strumento di Tracciamento</p>
<p className="textblockprivacy__h4">
Per Strumento di Tracciamento s'intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell'Utente.
</p>

<p className="textblockprivacy__h3">Riferimenti legali</p>
<p className="textblockprivacy__h4">
Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente Essence.
</p>

<hr className="separator" />

<p className="textblockprivacy__h4">
Ultima modifica: 21 novembre 2024 
</p>


    </div>
  );
}

export default TextBlockPrivacy;