import Spacer from "../components/Spacer";
import React from "react";
import Banner from "../components/Banner";
import BarMenu from "../components/BarMenu";
import NewFooter from '../components/NewFooter';
import TextBlockPrivacy from "../components/TextBlockPrivacy";

function Privacy() {
  return (
    <div>
      <Banner></Banner>
      <BarMenu></BarMenu>
      <Spacer></Spacer>
      <TextBlockPrivacy/>
      <Spacer></Spacer>
      <NewFooter></NewFooter>

    </div>
  );
}

export default Privacy;
