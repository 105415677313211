import "./TextBlockDelete.css";

const defaultTitle = "Elimina account";
const defaultText = "Puoi eliminare il tuo account in due modi.";
const emailText = "info@essence.lv";

const firstOption = "1) Tramite Essence, recandoti in Impostazioni > Profilo > Elimina account.";
const needInfo = "Per ogni altra evenienza, non esitare a contattarci all'indirizzo fornito!"

function TextBlockDelete({ title = defaultTitle, text = defaultText, email = emailText, steps }) {
  return (
    <div className="textblockdelete">
      <p className="textblockdelete__title">{title}</p>
      <p className="textblockdelete__text">{text}</p> 
      <p className="textblockdelete__title"><br /></p> 
      <p className="textblockdelete__text">{firstOption}</p> 
      <p className="textblockdelete__text">
  2) Inviandoci una richiesta via e-mail all'indirizzo <a href="mailto:info@essence.lv">{email}</a>, avente per oggetto "Richiesta eliminazione account". Si prega di mandare la richiesta dall'indirizzo e-mail utilizzato in fase di registrazione.
</p>
      <p className="textblockdelete__title"><br /></p>
      <p className="textblockdelete__text">{needInfo}</p>
    </div>
  );
}

export default TextBlockDelete;