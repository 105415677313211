import Spacer from "../components/Spacer";
import React from "react";
import Banner from "../components/Banner";
import BarMenu from "../components/BarMenu";
import NewFooter from '../components/NewFooter';
import TextBlockTerms from "../components/TextBlockTerms";

function Terms() {
  return (
    <div>
      <Banner></Banner>
      <BarMenu></BarMenu>
      <Spacer></Spacer>
      <TextBlockTerms/>
      <Spacer></Spacer>
      <NewFooter></NewFooter>

    </div>
  );
}

export default Terms;
