import "./TextBlockTerms.css";


function TextBlockTerms() {


  return (
    <div className="textblockterms">
      <p className="textblockterms__h1" style={{fontSize: '30px', marginBottom: '3.5rem' }}>Termini e Condizioni di Essence</p>
      <p className="textblockterms__h4">&nbsp;</p>


      <p className="textblockterms__h4">
        Questi Termini disciplinano
      </p>

      <p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
        • l'utilizzo di Essence e<br />
        • qualsiasi altro Accordo o rapporto giuridico con il Titolare
      </p>

      <p className="textblockterms__h4">
        in maniera vincolante. Le espressioni con l'iniziale maiuscola sono definite nella relativa sezione di questo documento.
      </p>
      <p className="textblockterms__h4">&nbsp;</p>

      <p className="textblockterms__h4">
        L'Utente è pregato di leggere attentamente questo documento.
      </p>
      <hr className="separator" />

      <p className="textblockterms__h4">
        Nonostante il rapporto contrattuale relativo all'acquisto di tali Prodotti sia concluso esclusivamente tra Titolare e Utenti, gli Utenti riconoscono e accettano che, qualora la fornitura di Essence sia avvenuta tramite l'Apple App Store, Apple potrebbe esercitare diritti derivanti da questi Termini in qualità di terzo beneficiario.
      </p>
      <p className="textblockterms__h4">&nbsp;</p>


      <p className="textblockterms__h4">
        Nessuna disposizione di questi Termini da origine ad un rapporto di lavoro dipendente, agenzia o associazione tra le parti interessate.
      </p>
      <p className="textblockterms__h4">&nbsp;</p>


      <p className="textblockterms__h4">
        Il soggetto responsabile di Essence è:
      </p>
      <p className="textblockterms__h4">&nbsp;</p>


      <p className="textblockterms__h4">
        Andrea Tempestini - Via S. G. Bosco 10, 21013, Gallarate (VA), Italia
      </p>
      <p className="textblockterms__h4">&nbsp;</p>

      <p className="textblockterms__h4">
        Indirizzo email del Titolare: andreatempestini@pec.it
      </p>
      <p className="textblockterms__h4">&nbsp;</p>

      <p className="textblockterms__h4">
        "Essence" si riferisce a
      </p>

      <p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
        • questo sito, inclusi i relativi sottodomini ed ogni altro sito attraverso i quali il Titolare offre il Servizio;<br />
        • applicazioni per dispositivi mobili, tablet o simili;<br />
        • le application program interfaces (API);<br />
        • il Servizio;<br />
        • qualsiasi software incluso come componente del Servizio, nonché ogni applicazione, file modello, file di contenuto, script, codice sorgente, set di istruzioni e la relativa documentazione;
      </p>
      <p className="textblockterms__h4">&nbsp;</p>


      <p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '1rem' }}>Da sapere a colpo d'occhio</p>

      <p className="textblockterms__h4" style={{ marginLeft: '20px', marginTop: '1rem' }}>
  • L'utilizzo del Servizio/di Essence è riservato ai soli Consumatori.<br />
  • L'utilizzo di Essence e del Servizio è riservato a Utenti maggiorenni ai sensi della legge applicabile.
</p>


      <hr className="separator" />

      <p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '4rem', marginBottom: '2rem' }}>CONDIZIONI D'USO</p>

<p className="textblockterms__h4">
  Salvo ove diversamente specificato, le condizioni d'uso di Essence esposte in questa sezione hanno validità generale.
</p>

<p className="textblockterms__h4">
  Ulteriori condizioni d'uso o d'accesso applicabili in particolari situazioni sono espressamente indicate in questo documento.
</p>

<p className="textblockterms__h4">
  Utilizzando Essence l'Utente dichiara di soddisfare i seguenti requisiti:
</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • L'Utente agisce come Consumatore;<br />
  • L'Utente è maggiorenne ai sensi della legge applicabile;<br />
  • L'Utente non è situato in un paese soggetto ad embargo governativo da parte degli Stati Uniti d'America o in un paese inserito nella lista degli stati che sponsorizzano il terrorismo da parte del Governo degli Stati Uniti d'America;<br />
  • L'Utente non è inserito in alcuna lista di contraenti non ammessi da parte del Governo degli Stati Uniti d'America;
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem', marginBottom: '1rem' }}>Registrazione</p>

<p className="textblockterms__h4">
  Per usufruire del Servizio l'Utente può aprire un account indicando tutti i dati e le informazioni richieste in maniera completa e veritiera.
  Non è possibile usufruire del Servizio senza aprire un account Utente.
</p>

<p className="textblockterms__h4">
  È responsabilità degli Utenti conservare le proprie credenziali d'accesso in modo sicuro e preservarne la confidenzialità. A tal fine, gli Utenti devono scegliere una password che corrisponda al più alto livello di sicurezza disponibile su Essence.
</p>

<p className="textblockterms__h4">
  Creando un account l'Utente accetta di essere pienamente responsabile di ogni attività posta in atto con le sue credenziali d'accesso. Gli Utenti sono tenuti a informare il Titolare immediatamente e univocamente tramite i recapiti indicati in questo documento qualora ritengano che le proprie informazioni personali, quali ad esempio l'account Utente, le credenziali d'accesso o dati personali, siano state violate, illecitamente diffuse o sottratte.
</p>

<p className="textblockterms__h3">Requisiti per la registrazione</p>

<p className="textblockterms__h4">
  La registrazione di un account Utente su Essence è soggetta alle condizioni sotto specificate. Registrando un account, l'Utente conferma di soddisfare tali condizioni.
</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • L'apertura di account tramite bot o altri mezzi automatizzati non è ammessa.<br />
  • Salvo ove diversamente specificato, ciascun Utente può creare un solo account.<br />
  • Salvo ove espressamente consentito, l'account di un Utente non può essere condiviso con altre persone.
</p>

<p className="textblockterms__h3">Chiusura account</p>

<p className="textblockterms__h4">
  L'Utente è libero di chiudere il proprio account e cessare l'utilizzo del Servizio in qualsiasi momento, seguendo questa procedura:
</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • Contattando il Titolare ai recapiti in questo documento.<br />
  • Aprendo l'applicazione Essence, andando su Impostazioni, Profilo, Elimina Account e cliccando su "Elimina account".
</p>

<p className="textblockterms__h3">Sospensione e cancellazione account</p>

<p className="textblockterms__h4">
  Il Titolare si riserva il diritto di sospendere o cancellare l'account di un Utente in qualsiasi momento a propria discrezione e senza preavviso, qualora lo ritenga inopportuno, offensivo o contrario a questi Termini.
</p>

<p className="textblockterms__h4">
  La sospensione o cancellazione dell'account non da all'Utente alcun diritto di risarcimento, rimborso o indennizzo.
</p>

<p className="textblockterms__h4">
  La sospensione o cancellazione di un account per cause addebitabili all'Utente non esonera l'Utente dal pagamento dei compensi o prezzi eventualmente applicabili.
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem'  ,marginBottom: '1rem' }}>Contenuti su Essence</p>

<p className="textblockterms__h4">
  Salvo ove diversamente specificato o chiaramente riconoscibile, tutti i contenuti disponibili su Essence sono di proprietà di o forniti dal Titolare o dei/dai suoi licenzianti.
</p>

<p className="textblockterms__h4">
  Il Titolare adotta la massima cura affinché il contenuto disponibile su Essence non violi la normativa applicabile o diritti di terze parti. Tuttavia, non sempre è possibile raggiungere tale risultato.
  In tali casi, senza alcun pregiudizio ai diritti ed alle pretese legalmente esercitabili, gli Utenti sono pregati di indirizzare i relativi reclami ai recapiti specificati in questo documento.
</p>

<p className="textblockterms__h3">Diritti sui contenuti di Essence</p>

<p className="textblockterms__h4">
  Il Titolare detiene e si riserva espressamente ogni diritto di proprietà intellettuale sui suddetti contenuti.
</p>

<p className="textblockterms__h4">
  Gli Utenti non sono autorizzati ad usare i contenuti in alcun modo che non sia necessario od implicito nel corretto utilizzo del Servizio.
</p>

<p className="textblockterms__h4">
  In particolare, ma senza esclusioni, è fatto divieto agli Utenti di copiare, scaricare, condividere oltre i limiti sotto specificati, modificare, tradurre, elaborare, pubblicare, trasmettere, vendere, concedere sottolicenze, trasformare, trasferire/alienare a terze parti o creare opere derivate a partire dal contenuto disponibile su Essence, di permettere a terze parti di intraprendere tali attività tramite il proprio account Utente o dispositivo, anche a propria insaputa.
</p>

<p className="textblockterms__h4">
  Ove espressamente indicato su Essence, l'Utente è autorizzato a scaricare, copiare e/o condividere determinati contenuti disponibili su Essence esclusivamente per scopi personali e non commerciali ed a condizione che sia osservata l'attribuzione della paternità dell'opera nonché l'indicazione di ogni altra circostanza rilevante richiesta dal Titolare.
</p>

<p className="textblockterms__h4">
  Restano ferme le limitazioni ed esclusioni previste dalla normativa sul diritto d'autore.
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem' ,marginBottom: '1rem'}}>Contenuti forniti dagli Utenti</p>

<p className="textblockterms__h4">
  Il Titolare consente agli Utenti di caricare, condividere o offrire i propri contenuti su Essence.
</p>

<p className="textblockterms__h4">
  All'atto di fornire contenuti a Essence l'Utente dichiara di essere legalmente autorizzato a farlo e conferma che detti contenuti non violano norme di legge e/o diritti di terzi.
</p>

<p className="textblockterms__h4">
  L'Utente riconosce ed accetta che fornendo contenuti propri a Essence concede al Titolare irrevocabilmente e a titolo gratuito il diritto non esclusivo, valido in tutto il mondo e perpetuo (ovvero per la durata della protezione legale), di utilizzare, accedere, archiviare, riprodurre, modificare, diffondere, pubblicare, trasformare in opere derivate, trasmettere (incluso lo streaming) o sfruttare in altro modo i contenuti per fornire e promuovere il Servizio in ogni forma o canale mediatico. Detto diritto può essere trasferito o concesso in licenza a terzi.
</p>

<p className="textblockterms__h4">
  Nei limiti di legge, l'Utente rinuncia all'esercizio di diritti morali in relazione al contenuto fornito a Essence.
</p>

<p className="textblockterms__h4">
  Gli Utenti riconoscono ed accettano che i contenuti da loro offerti tramite Essence saranno resi disponibili alle stesse condizioni applicabili ai contenuti di Essence.
</p>

<p className="textblockterms__h3">Responsabilità per i contenuti forniti</p>

<p className="textblockterms__h4">
  L'Utente risponde in via esclusiva dei contenuti caricati, pubblicati, condivisi o comunque forniti a Essence. L'Utente riconosce ed accetta che il Titolare non filtra né modera tali contenuti.
</p>

<p className="textblockterms__h4">
  Ciononostante, il Titolare si riserva il diritto di rimuovere, cancellare, bloccare o rettificare detti contenuti a propria discrezione e di negare senza preavviso all'Utente che li ha caricati l'accesso a Essence:
</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • se ha ricevuto un reclamo in relazione a tale contenuto;<br />
  • se ha ricevuto una notifica di violazione dei diritti di proprietà intellettuale;<br />
  • per ordine dell'Autorità; o<br />
  • qualora sia stato fatto presente al Titolare che tali contenuti, se accessibili tramite Essence, possono rappresentare un rischio per gli Utenti, per i terzi o per la disponibilità del Servizio.
</p>

<p className="textblockterms__h4">
  La rimozione, cancellazione, il blocco o la rettifica dei contenuti non giustificano alcuna pretesa di risarcimento, rimborso o indennizzo in capo agli Utenti che hanno fornito tali contenuti.
</p>

<p className="textblockterms__h4">
  Gli Utenti accettano di mantenere indenne il Titolare da e contro ogni pretesa avanzata e/o danno sofferto a causa di contenuti da essi forniti a o offerti tramite Essence.
</p>

<p className="textblockterms__h3">Rimozione di contenuti da parte di Essence disponibili tramite App Store</p>

<p className="textblockterms__h4">
  Se un contenuto oggetto di reclamo è considerato contestabile sarà rimosso entro 24 ore e all'Utente responsabile del caricamento sarà negato l'accesso al Servizio.
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem',marginBottom: '1rem' }}>Accesso a risorse esterne</p>

<p className="textblockterms__h4">
  Tramite Essence gli Utenti potrebbero avere accesso a risorse fornite da terzi. Gli Utenti riconoscono ed accettano che il Titolare non ha alcun controllo su tali risorse e che pertanto non risponde del loro contenuto e della loro disponibilità.
</p>

<p className="textblockterms__h4">
  Le condizioni applicabili alle risorse fornite da terzi, ivi incluse quelle applicabili a eventuali concessioni di diritti su contenuti, sono determinate dagli stessi terzi e regolate nei relativi termini e condizioni o, in loro assenza, dalla legge.
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem',marginBottom: '1rem' }}>Uso ammesso</p>

<p className="textblockterms__h4">
  Essence ed il Servizio possono essere utilizzati solo per gli scopi per i quali sono offerti, secondo questi Termini ed ai sensi della legge applicabile.
</p>

<p className="textblockterms__h4">
  È responsabilità esclusiva dell'Utente di far sì che l'uso di Essence e/o del Servizio non violi la legge, i regolamenti o i diritti di terzi.
</p>

<p className="textblockterms__h4">
  Pertanto, il Titolare si riserva il diritto di adottare ogni misura idonea a proteggere i propri interessi legittimi, ed in particolare di negare all'Utente l'accesso a Essence o al Servizio, risolvere contratti, denunciare ogni attività censurabile svolta tramite Essence o il Servizio alle autorità competenti – p. es. l'autorità giudiziaria o amministrativa – ogniqualvolta sussista il sospetto che l'Utente ponga in essere violazioni di legge, regolamenti, diritti di terzi e/o i Termini, in particolare, ma senza esclusioni, mettendo in atto una delle seguenti attività:
</p>

<p className="textblockterms__h3">Regole di comportamento</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • fingere di soddisfare un qualsiasi requisito di accesso a Essence o di utilizzo del Servizio, come ad esempio la maggiore età o la qualifica di Consumatore;<br />
  • nascondere la propria identità, utilizzare l'identità altrui o fingere di agire in nome di un terzo, se non autorizzati da tale terzo;<br />
  • alterare identificativi per nascondere o camuffare l'origine dei propri messaggi o dei contenuti pubblicati;<br />
  • diffamare, minacciare, abusare, usare pratiche intimidatorie, minacciare o violare i diritti di terzi in qualsiasi altro modo;<br />
  • promuovere attività che possono mettere a rischio la vita propria o di qualsiasi altro Utente o causare danni fisici. Sono comprese in questa categoria, ma senza alcuna esclusione, la minaccia di o istigazione al suicidio, l'esaltazione di traumi fisici intenzionali, l'uso di droghe illegali, l'abuso di alcol. La pubblicazione di contenuti che promuovono, esaltano o illustrano atteggiamenti autodistruttivi o violenti su Essence non è tollerata in alcun caso;<br />
  • mettere alla prova, analizzare o testare la vulnerabilità di Essence, i servizi e le reti collegate al sito, violare la sicurezza o le procedure di autenticazione su Essence, i servizi e le reti collegate a Essence;<br />
  • installare, integrare, caricare o altrimenti incorporare malware in o tramite Essence;<br />
  • usare Essence o la relativa infrastruttura tecnologica in maniera abusiva, eccessiva o altrimenti inappropriata (ad esempio: a scopo di spam);<br />
  • tentare di disgregare o manomettere l'infrastruttura tecnologica in modo tale da causare un danno o un onere eccessivo a Essence o al Servizio;<br />
  • offrire tramite Essence Prodotti la cui vendita è vietata dalla legge o che non sono attualmente disponibili (p. es. per esaurimento scorte);<br />
  • manipolare il prezzo dei Prodotti offerti o omettere la consegna di Prodotti acquistati;
</p>

<p className="textblockterms__h3">Uso eccessivo del Servizio</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • utilizzare le risorse di Essence in modo eccessivo rispetto ad altri Utenti di Essence – in tal caso il Titolare si riserva il diritto ulteriore di sospendere l'account dell'Utente o limitarne l'attività finché l'Utente non cessi l'uso eccessivo, a propria discrezione;
</p>

<p className="textblockterms__h3">Uso eccessivo dell'API</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • inviare richieste abusive o eccessivamente frequenti al Servizio tramite API. Il Titolare determinerà in cosa consista l'abuso o la richiesta eccessivamente frequente dell'API e si riserva inoltre il diritto di sospendere l'accesso dell'Utente all'API in via temporanea o definitiva. In tal caso, il Titolare nei limiti del ragionevole tenterà di allertare l'Utente prima di procedere alla sospensione;
</p>

<p className="textblockterms__h3">Scraping</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • implementare processi automatizzati di estrazione, raccolta o captazione di informazioni, dati e/o contenuti da Essence e da tutte le relative estensioni digitali, se non espressamente autorizzati dal Titolare;
</p>

<p className="textblockterms__h3">Regole di contenuto</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • diffondere o pubblicare contenuti illeciti, osceni, illegittimi, diffamatori o inadeguati;<br />
  • pubblicare contenuti che direttamente o indirettamente promuovono odio, razzismo, discriminazione, pornografia o violenza;<br />
  • diffondere o pubblicare contenuti falsi o che possono provocare ingiustificato allarme;<br />
  • usare Essence per pubblicare, diffondere o altrimenti offrire contenuti tutelati dalla normativa sulla proprietà intellettuale, compresi, ma senza esclusione, brevetti, marchi e diritto d'autore, senza l'autorizzazione del titolare dei diritti;<br />
  • usare Essence per pubblicare, diffondere o altrimenti offrire contenuti che violano diritti di terzi, compresi, ma senza esclusione, segreti militari, commerciali, professionali o di stato e dati personali;<br />
  • pubblicare contenuti o svolgere attività che disgregano, interrompono, danneggiano o violano in ogni altro modo l'integrità di Essence o dei dispositivi di altri Utenti. Sono comprese tra tali attività: lo spamming, la diffusione illecita di pubblicità, il phishing, la frode a danno di terzi, la diffusione di malware o virus etc.;<br />
  • pubblicare o diffondere in ogni altro modo contenuti falsi, non corretti, fuorvianti, diffamatori o offensivi in relazione a Prodotti offerti su Essence;
</p>

<p className="textblockterms__h3">Tutela dell'Utente</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • utilizzare abusivamente l'account di un altro Utente;<br />
  • raccogliere o estrarre informazioni personali ed identificative di altri Utenti, comprese, senza alcuna esclusione, indirizzi di posta elettronica o recapiti, violando le impostazioni di riservatezza degli account di altri Utenti su Essence o in qualsiasi altro modo;<br />
  • utilizzare informazioni relative ad altri Utenti, quali dati personali o di contatto, per scopi diversi da quelli di Essence;
</p>

<p className="textblockterms__h3">Divieti d'uso commerciale</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • aprire un account o usare Essence per promuovere, vendere o pubblicizzare prodotti o servizi di qualsiasi tipo in qualsiasi modo;<br />
  • fingere o implicare in qualsiasi maniera di avere un rapporto con Essence, di godere del sostegno di Essence o che Essence sostenga i prodotti o servizi dell'Utente o di un terzo per qualsiasi scopo;
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem',marginBottom: '1rem' }}>Licenza software</p>

<p className="textblockterms__h4">
  Qualsiasi diritto di proprietà intellettuale o industriale, così come ogni altro diritto esclusivo esistente sul software o sulla tecnologia integrata in o relativa a Essence è detenuto dal Titolare e/o dal suo licenziante.
</p>

<p className="textblockterms__h4">
  A condizione che l'Utente rispetti questi Termini e nonostante qualsiasi disposizione divergente in essi contenuta, il Titolare concede agli Utenti una licenza revocabile, non esclusiva non cedibile né concedibile di usare il software e/o la tecnologia integrata nel Servizio nel quadro e per gli scopi di Essence e del Servizio offerto.
</p>

<p className="textblockterms__h4">
  La licenza non comprende alcun diritto di accesso a, utilizzo o rivelazione del codice sorgente originale all'Utente. Le tecniche, gli algoritmi e le procedure contenute nel software e la relativa documentazione sono di proprietà esclusiva del Titolare o del suo licenziante.
</p>

<p className="textblockterms__h4">
  La concessione di diritti e licenze all'Utente cessa con effetti immediati in caso di risoluzione o scadenza dell'Accordo.
</p>

<p className="textblockterms__h4">
  Fermo restando quando sopra, ai sensi di questa licenza l'Utente può scaricare, installare, utilizzare ed operare il software sul numero consentito di dispositivi a condizione che tali dispositivi siano di uso comune, aggiornati alle ultime tecnologie ed agli standard di mercato.
</p>

<p className="textblockterms__h4">
  Il Titolare si riserva il diritto di rilasciare aggiornamenti, correzioni o ulteriori sviluppi di Essence e/o del relativo software e di metterli gratuitamente a disposizione dell'Utente. Gli Utenti potrebbero essere tenuti a scaricare e installare tali aggiornamenti per poter continuare ad utilizzare Essence e/o il software relativo.
</p>

<p className="textblockterms__h4">
  Nuove versioni potrebbero essere disponibili a pagamento.
</p>

<p className="textblockterms__h4">
  Gli Utenti possono scaricare, installare e utilizzare il software su un dispositivo.
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem',marginBottom: '1rem' }}>Condizioni d'uso dell'API</p>

<p className="textblockterms__h4">
  Gli Utenti possono accedere ai propri dati relativi a Essence attraverso l'Application Program Interface (API). Ogni utilizzo dell'API, anche attraverso prodotti o servizi di terzi che accedono a Essence, è soggetto ai Termini e in aggiunta alle seguenti condizioni specifiche:
</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • l'Utente riconosce ed accetta espressamente che il Titolare non risponde di danni o perdite conseguenti all'utilizzo da parte dell'Utente dell'API o di prodotti o servizi terzi che accedono a dati tramite l'API.
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem' }}>Limitazione di responsabilità e manleva</p>
<p className="textblockterms__h3">Manleva</p>

<p className="textblockterms__h4">
  L'Utente si impegna a manlevare e tenere indenne il Titolare e i suoi sottoposti, affiliati, funzionari, agenti, contitolari del marchio, partner e dipendenti da qualsivoglia rivendicazione o pretesa – compresi, senza alcuna limitazione, oneri e spese legali – avanzata da terzi a causa di o in collegamento con comportamenti colposi quali l'utilizzo o la connessione al servizio, la violazione dei presenti Termini, la violazione di diritti di terze parti o di leggi da parte dell'Utente, dei suoi affiliati, funzionari, agenti, contitolari del marchio, partner e dipendenti, nella misura di legge.
</p>

<p className="textblockterms__h3">Limitazione della responsabilità per le attività dell'Utente su Essence</p>

<p className="textblockterms__h4">
  Salvo ove diversamente specificato e fatte salve le disposizioni di legge applicabili in materia di responsabilità per danno da prodotto, è esclusa ogni pretesa risarcitoria nei confronti del Titolare (o di qualsiasi persona fisica o giuridica che agisca per suo conto).
</p>

<p className="textblockterms__h4">
  Quanto precede non limita la responsabilità del Titolare per morte, danno alla persona o all'integrità fisica o mentale, danni derivanti dalla violazione di obblighi contrattuali essenziali, quali gli obblighi strettamente necessari al raggiungimento della causa del contratto, e/o ai danni provocati con dolo o colpa grave, a condizione che l'utilizzo di Essence da parte dell'Utente sia stato idoneo e corretto.
</p>

<p className="textblockterms__h4">
  Salvo che i danni siano stati causati con dolo o colpa grave o incidano sulla vita e/o l'integrità personale, fisica o mentale, il Titolare risponde solo nella misura del danno tipico per il tipo di contratto e prevedibile al momento della conclusione.
</p>

<p className="textblockterms__h4">
  In particolare, nei limiti sopra riportati, il Titolare non si assume alcuna responsabilità per quanto riguarda:
</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • eventuali mancati guadagni o altre perdite, anche indirette, che l'Utente potrebbe aver subito (quali, a mero titolo esemplificativo, perdite commerciali, perdita di ricavi, di profitti o risparmi preventivati, perdita di rapporti contrattuali o commerciali, perdita di avviamento o danni alla reputazione, etc.);<br />
  • danni o perdite derivanti da interruzioni o malfunzionamenti di Essence dovuti a cause di forza maggiore o eventi imprevisti ed imprevedibili e, in ogni caso, indipendenti dalla volontà e fuori dal controllo del Titolare, quali, a mero titolo esemplificativo, guasti o interruzioni delle linee telefoniche o elettriche, della connessione Internet e/o di altri mezzi di trasmissione, inaccessibilità di siti web, scioperi, calamità naturali, virus e attacchi informatici, interruzioni di fornitura dei prodotti, servizi o applicazioni di terzi;<br />
  • eventuali perdite che non siano conseguenza diretta di una violazione dei Termini da parte del Titolare;<br />
  • Danni, pregiudizi o perdite dovuti a virus o altri malware contenuti o connessi a file scaricabili da Internet o tramite Essence. Gli Utenti sono responsabili dell'adozione di misure di sicurezza adeguate – come gli antivirus – e di firewall per prevenire eventuali infezioni o attacchi e per proteggere le copie di backup di tutti i dati e/o informazioni scambiati/e o caricati/e su Essence.
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem' }}>Utenti australiani</p>
<p className="textblockterms__h3">Limitazione della responsabilità</p>

<p className="textblockterms__h4">
  Nessuna disposizione di questi Termini esclude, limita o modifica alcuna garanzia, condizione, manleva, diritto o tutela che l'Utente possa avere ai sensi del Competition and Consumer Act 2010 (Cth) o di altre simili legislazioni statali e territoriali e che costituisca un diritto che non può in alcun modo essere escluso, limitato o modificato (diritto non escludibile). Nei limiti massimi consentiti dalla legge, la nostra responsabilità nei confronti dell'Utente, inclusa la responsabilità per la violazione di un diritto non escludibile e ogni altra responsabilità non altrimenti esclusa ai sensi di questi Termini e Condizioni, è limitata, a discrezione del Titolare, ad una nuova fornitura dei servizi o al pagamento del costo per la ripetizione della loro fornitura.
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem' }}>Utenti USA</p>
<p className="textblockterms__h3">Esclusione di garanzia</p>

<p className="textblockterms__h4">
  Il Titolare fornisce Essence "così com'è" e secondo disponibilità. L'utilizzo del Servizio è a rischio e pericolo dell'Utente. Nei limiti massimi consentiti dalla legge, il Titolare esclude espressamente le condizioni, pattuizioni e garanzie di qualsiasi tipo – siano esse espresse, implicite, statuarie o di altro tipo, comprese, a titolo esemplificativo ma non esaustivo, eventuali garanzie implicite di commerciabilità, di idoneità per un fine particolare o di non-violazione dei diritti di terzi. Nessuna consulenza o informazione, sia essa orale o scritta, che l'Utente abbia ottenuto dal Titolare o tramite il Servizio creerà garanzie non espressamente previste nel presente documento
</p>

<p className="textblockterms__h4">
  Fermo restando quanto sopra, il Titolare e i suoi sottoposti, affiliati, funzionari, agenti, contitolari del marchio, partner, fornitori e dipendenti non garantiscono che il contenuto sia accurato, affidabile o corretto; che il Servizio sarà disponibile, in maniera ininterrotta e sicura, in qualsiasi momento o luogo particolare; che eventuali difetti o errori saranno corretti; o che il Servizio sia privo di virus o altri componenti dannosi. Qualsiasi contenuto scaricato o altrimenti ottenuto tramite l'utilizzo del Servizio è scaricato a rischio e pericolo dell'Utente e l'Utente è l'unico responsabile per qualsiasi danno al sistema informatico o al dispositivo mobile o per la perdita di dati risultante da tale operazione o dall'uso del Servizio da parte dell'Utente.
</p>

<p className="textblockterms__h4">
  Il Titolare non garantisce, approva, assicura o si assume la responsabilità per nessun prodotto o servizio pubblicizzato o offerto da parti terze attraverso il Servizio né nessun sito web o servizio collegato tramite hyperlink. Inoltre il Titolare non prende parte né in alcun modo monitora nessuna transazione tra Utenti e fornitori terzi di prodotti o servizi.
</p>

<p className="textblockterms__h4">
  Il Servizio potrebbe diventare inaccessibile o non funzionare correttamente con il browser, dispositivo e/o sistema operativo dell'Utente. Il Titolare non può essere ritenuto responsabile per nessun danno, sia esso percepito o effettivo, derivante dal contenuto, funzionamento o utilizzo del Servizio.
</p>

<p className="textblockterms__h4">
  Leggi federali, alcuni stati e altre giurisdizioni non consentono l'esclusione e la limitazione di determinate garanzie implicite. Le esclusioni di cui sopra potrebbero non applicarsi agli Utenti. Questo Accordo conferisce agli Utenti diritti legali particolari. Gli Utenti potrebbero godere di diritti ulteriori che variano da stato a stato. Le limitazioni ed esclusioni previste da questo Accordo si applicano nei limiti previsti dalla legge.
</p>

<p className="textblockterms__h3">Limitazione della responsabilità</p>

<p className="textblockterms__h4">
  Nei limiti massimi consentiti dalla legge applicabile, in nessun caso il Titolare e i suoi sottoposti, affiliati, funzionari, agenti, contitolari del marchio, partner, fornitori e dipendenti potranno essere ritenuti responsabili per
</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • qualsiasi danno indiretto, intenzionale, collaterale, particolare, consequenziale o esemplare, inclusi, a titolo esemplificativo ma non esaustivo, danni derivanti dalla perdita di profitti, di avviamento, d'uso, di dati o altre perdite immateriali, derivanti da o relativi all'uso, o all'impossibilità di usare il Servizio; e<br />
  • qualsiasi danno, perdita o lesione risultante da hackeraggio, manomissione o da altro accesso o uso non autorizzato del Servizio o dell'account Utente o delle informazioni in esso contenute;<br />
  • qualsiasi errore, mancanza o imprecisione nei contenuti;<br />
  • lesioni personali o danni materiali, di qualsiasi natura, derivanti dall'accesso o dall'utilizzo del Servizio da parte dell'Utente;<br />
  • qualsiasi accesso non autorizzato ai server di sicurezza del Titolare e/o a qualsivoglia informazione personale ivi memorizzata<br />
  • qualsiasi interruzione o cessazione delle trasmissioni da o verso il Servizio;<br />
  • qualsiasi bug, virus, trojan o simili che possono essere trasmessi al o tramite il Servizio;<br />
  • qualsiasi errore o omissione in qualsiasi contenuto o per qualsiasi perdita o danno subiti a seguito dell'uso di qualsiasi contenuto pubblicato, inviato via email, trasmesso o altrimenti reso disponibile attraverso il Servizio; e/o<br />
  • la condotta diffamatoria, offensiva o illegale di qualsiasi Utente o di terzi. In nessun caso il Titolare e i suoi sottoposti, affiliati, funzionari, agenti, contitolari del marchio, partner, fornitori e dipendenti potranno essere ritenuti responsabili per qualsiasi richiesta di risarcimento, procedimento, responsabilità, obbligo, danno, perdita o costo per un importo superiore a quello pagato dall'Utente al Titolare nel corso dei 12 mesi precedenti, o per il periodo di durata di questo Accordo tra il Titolare e l'Utente, a seconda di quale dei due è più breve.
</p>

<p className="textblockterms__h4">
  Questa sezione sulla limitazione di responsabilità si applica nei limiti massimi consentiti dalla legge nella giurisdizione applicabile, indipendentemente dal fatto che la presunta responsabilità derivi da contratto, atto illecito, negligenza, responsabilità oggettiva o da qualsiasi altro fondamento, anche se il Titolare era stato avvisato della possibilità del verificarsi di tale danno. Alcune giurisdizioni non consentono l'esclusione o la limitazione dei danni collaterali e consequenziali, pertanto le limitazioni o esclusioni di cui sopra potrebbero non applicarsi all'Utente. Questi Termini conferiscono all'Utente diritti legali specifici e l'Utente potrebbe godere di altri diritti che variano da giurisdizione a giurisdizione. Le deroghe, esclusioni o limitazioni di responsabilità previste da questi Termini non si applicano oltre i limiti previsti dalla legge applicabile.
</p>

<p className="textblockterms__h3">Manleva</p>

<p className="textblockterms__h4">
  L'Utente si impegna a difendere, manlevare e tenere indenne il Titolare e i suoi sottoposti, affiliati, funzionari, agenti, contitolari del marchio, partner, fornitori e dipendenti da e contro qualsivoglia rivendicazione o pretesa, danno, obbligazione, perdita, responsabilità, onere o debito e spesa, compresi, senza alcuna limitazione, oneri e spese legali derivanti da
</p>

<p className="textblockterms__h4" style={{ marginLeft: '20px' }}>
  • l'uso o l'accesso al Servizio da parte dell'Utente, compresi gli eventuali dati o contenuti trasmessi o ricevuti dall'Utente;<br />
  • la violazione di questi Termini da parte dell'Utente, comprese, a titolo esemplificativo ma non esaustivo, eventuali violazioni da parte dell'Utente di qualsivoglia dichiarazione o garanzia prevista da questi Termini;<br />
  • la violazione da parte dell'Utente di qualsiasi diritto di terzi, compreso, ma non limitato a, qualsiasi diritto relativo alla privacy o alla proprietà intellettuale;<br />
  • la violazione da parte dell'Utente di qualsiasi legge, norma o regolamento vigente<br />
  • qualsiasi contenuto inviato dall'account dell'Utente, incluse, a titolo esemplificativo ma non esaustivo, informazioni fuorvianti, false o imprecise e compreso anche il caso in cui l'accesso venga effettuato da terzi con username e password personali dell'Utente o altre misure di sicurezza, se presenti;<br />
  • la condotta dolosa dell'Utente; o<br />
  • la violazione di qualsivoglia disposizione legale da parte dell'Utente o dei suoi affiliati, funzionari, agenti, contitolari del marchio, partner, fornitori e dipendenti, nei limiti consentiti dalla legge applicabile
</p>

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '3rem' }}>Disposizioni comuni</p>
<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem',marginBottom: '1rem' }}>Nessuna rinuncia implicita</p>

<p className="textblockterms__h4">
  Il mancato esercizio di diritti di legge o pretese derivanti da questi Termini da parte del Titolare non costituisce rinuncia agli stessi. Nessuna rinuncia può essere considerata definitiva in relazione ad uno specifico diritto o a qualsiasi altro diritto.
</p>

<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem',marginBottom: '1rem' }}>Interruzione del Servizio</p>

<p className="textblockterms__h4">
  Per garantire il miglior livello di servizio possibile, il Titolare si riserva di interrompere il Servizio per finalità di manutenzione, aggiornamenti di sistema o per qualsiasi altra modifica, dandone idonea notizia agli Utenti.
</p>

<p className="textblockterms__h4">
  Nei limiti di legge, il Titolare si riserva di sospendere o terminare completamente il Servizio. In caso di terminazione del Servizio, il Titolare si adopererà affinché gli Utenti possano estrarre i propri Dati Personali e le informazioni secondo le disposizioni di legge.
</p>

<p className="textblockterms__h4">
  Inoltre, il Servizio potrebbe non essere disponibile per cause che si sottraggono al ragionevole controllo del Titolare, quali cause di forza maggiore (p. es. scioperi, malfunzionamenti infrastrutturali, blackout etc.).
</p>

<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem',marginBottom: '1rem' }}>Rivendita del Servizio</p>

<p className="textblockterms__h4">
  Gli Utenti non sono autorizzati a riprodurre, duplicare, copiare, vendere, rivendere o sfruttare Essence o il Servizio in toto o in parte senza previo consenso scritto del Titolare, espresso direttamente o attraverso un legittimo programma di rivendite.
</p>

<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem',marginBottom: '1rem' }}>Privacy policy</p>

<p className="textblockterms__h4">
  Le informazioni sul trattamento dei Dati Personali sono contenute nella privacy policy di Essence.
</p>

<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem',marginBottom: '1rem' }}>Proprietà intellettuale</p>

<p className="textblockterms__h4">
  Senza pregiudizio ad alcuna previsione più specifica contenuta nei Termini, i diritti di proprietà intellettuale ed industriale, quali ad esempio diritti d'autore, marchi, brevetti e modelli relativi a Essence sono detenuti in via esclusiva dal Titolare o dai suoi licenzianti e sono tutelati ai sensi della normativa e dei trattati internazionali applicabili alla proprietà intellettuale.
</p>

<p className="textblockterms__h4">
  Tutti i marchi – denominativi o figurativi – ed ogni altro segno distintivo, ditta, marchio di servizio, illustrazione, immagine o logo che appaiono in collegamento con Essence sono e restano di esclusiva proprietà del Titolare o dei suoi licenzianti e sono tutelati ai sensi della normativa e dei trattati internazionali applicabili alla proprietà intellettuale.
</p>

<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem',marginBottom: '1rem' }}>Modifiche dei Termini</p>

<p className="textblockterms__h4">
  Il Titolare si riserva il diritto di modificare i Termini in ogni momento. In tal caso, il Titolare darà opportuna notizia delle modifiche agli Utenti.
</p>

<p className="textblockterms__h4">
  Le modifiche avranno effetti sul rapporto con l'Utente solo per il futuro.
</p>

<p className="textblockterms__h4">
  L'utilizzo continuato del Servizio implica l'accettazione dell'Utente dei Termini aggiornati. Se l'Utente non desidera accettare le modifiche, deve cessare l'utilizzo del Servizio. La mancata accettazione dei Termini aggiornati potrebbe comportare la facoltà di ciascuna parte di recedere dall'Accordo.
</p>

<p className="textblockterms__h4">
  La versione precedente applicabile continua a disciplinare il rapporto fino all'accettazione dell'Utente. Tale versione può essere richiesta al Titolare.
</p>

<p className="textblockterms__h4">
  Se richiesto dalla legge applicabile, il Titolare specificherà la data entro cui le modifiche ai Termini entreranno in vigore.
</p>

<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem' ,marginBottom: '1rem'}}>Cessione del contratto</p>

<p className="textblockterms__h4">
  Il Titolare si riserva il diritto di trasferire, cedere, disporre di, novare o appaltare singoli o tutti i diritti e le obbligazioni secondo questi Termini, avendo riguardo per gli interessi legittimi degli Utenti.
</p>

<p className="textblockterms__h4">
  Si applicano le disposizioni relative alla modifica di questi Termini.
</p>

<p className="textblockterms__h4">
  L'Utente non è autorizzato a cedere o trasferire i propri diritti e le proprie obbligazioni secondo i Termini senza il consenso scritto del Titolare.
</p>

<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem',marginBottom: '1rem' }}>Contatti</p>

<p className="textblockterms__h4">
  Tutte le comunicazioni inerenti all'uso di Essence devono essere inviate ai recapiti indicati in questo documento.
</p>

<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem',marginBottom: '1rem' }}>Clausola di salvaguardia</p>

<p className="textblockterms__h4">
  Qualora alcuna delle disposizioni di questi Termini dovesse essere o divenire nulla o inefficace ai sensi della legge applicabile, la nullità o inefficacia di tale disposizione non provoca inefficacia delle restanti previsioni, che permangono pertanto valide ed efficaci.
</p>

<p className="textblockterms__h3">Utenti USA</p>

<p className="textblockterms__h4">
  Qualsiasi disposizione invalida o inefficace sarà interpretata ed adattata nei limiti necessari per renderla valida, efficace e conforme alla finalità originaria.
</p>

<p className="textblockterms__h4">
  Questi Termini costituiscono l'intero accordo tra Utente e Titolare con riferimento all'oggetto regolato e prevalgono su ogni altra comunicazione, compresi eventuali accordi precedenti, tra le parti in merito all'oggetto regolato.
</p>

<p className="textblockterms__h4">
  Questi Termini saranno attuati nella più ampia misura consentita dalla legge.
</p>

<p className="textblockterms__h3">Utenti europei</p>

<p className="textblockterms__h4">
  Qualora una disposizione di questi Termini dovesse essere o divenire nulla, invalida o inefficace, le parti si adopereranno per individuare in via amichevole una disposizione valida ed efficace sostitutiva di quella nulla, invalida o inefficace.
</p>

<p className="textblockterms__h4">
  In caso di mancato accordo nei termini predetti, se permesso o previsto dalla legge applicabile, la disposizione nulla, invalida o inefficace sarà sostituita dalla disciplina legale applicabile.
</p>

<p className="textblockterms__h4">
  Fermo restando quanto sopra, la nullità, invalidità o inefficacia di una specifica disposizione di questi Termini non comporta nullità dell'intero Accordo, a meno che le disposizioni nulle, invalidi o inefficaci nel quadro dell'Accordo siano essenziali o di tale importanza, che le parti non avrebbero concluso il contratto se avessero saputo che la disposizione sarebbe stata invalida, ovvero in casi in cui le disposizioni residue comporterebbero un onere eccessivo ed inaccettabile per una delle parti.
</p>

<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem',marginBottom: '1rem' }}>Legge applicabile</p>

<p className="textblockterms__h4">
  I Termini sono disciplinati dalla legge del luogo in cui è stabilito il Titolare, così come indicato nella relativa sezione di questo documento a prescindere dalle norme di conflitto.
</p>

<p className="textblockterms__h3">Eccezione per Consumatori Europei</p>

<p className="textblockterms__h4">
  Tuttavia, a prescindere da quanto precede, se l'Utente agisce come Consumatore Europeo ed ha residenza abituale in un paese la cui legge prevede un livello di tutela dei consumatori superiore, prevale tale superiore livello di tutela.
</p>

<p className="textblockterms__h2" style={{fontSize: '20px', marginTop: '3rem',marginBottom: '1rem' }}>Foro competente</p>

<p className="textblockterms__h4">
  La competenza esclusiva a conoscere qualsiasi controversia derivante da o in collegamento con i Termini spetta al giudice del luogo in cui il Titolare è stabilito, così come indicato nella relativa sezione di questo documento.
</p>

<p className="textblockterms__h3">Eccezione per Consumatori Europei</p>

<p className="textblockterms__h4">
  Quanto precede non si applica a Utenti che agiscono come Consumatori Europei o Consumatori situati in Svizzera, Norvegia o Islanda.
</p>

<hr className="separator" />

<p className="textblockterms__h2" style={{fontSize: '24px', marginTop: '0rem' }}>Definizioni e riferimenti legali</p>

<p className="textblockterms__h3">Essence (o questa Applicazione)</p>

<p className="textblockterms__h4">
  La struttura che consente la fornitura del Servizio.
</p>

<p className="textblockterms__h3">Accordo</p>

<p className="textblockterms__h4">
  Qualsiasi rapporto legalmente vincolante o contrattuale tra il Titolare e l'Utente disciplinato dai Termini.
</p>

<p className="textblockterms__h3">Europeo (o Europa)</p>

<p className="textblockterms__h4">
  Definisce un Utente fisicamente presente o con sede legale nell'Unione Europea, a prescindere dalla nazionalità.
</p>

<p className="textblockterms__h3">Titolare (o Noi)</p>

<p className="textblockterms__h4">
  Indica la persona fisica o giuridica che fornisce Essence e/o offre il Servizio agli Utenti.
</p>

<p className="textblockterms__h3">Servizio</p>

<p className="textblockterms__h4">
  Il servizio offerto tramite Essence così come descritto nei Termini e su Essence.
</p>

<p className="textblockterms__h3">Termini</p>

<p className="textblockterms__h4">
  Tutte le condizioni applicabili all'utilizzo di Essence e/o alla fornitura del Servizio così come descritti in questo documento nonché in qualsiasi altro documento o accordo ad esso collegato, nella versione rispettivamente più aggiornata.
</p>

<p className="textblockterms__h3">Utente (o Tu)</p>

<p className="textblockterms__h4">
  Indica qualsiasi persona fisica che utilizzi Essence.
</p>

<p className="textblockterms__h3">Consumatore</p>

<p className="textblockterms__h4">
  Qualsiasi persona fisica che, in qualità di Utente, utilizza beni o servizi per scopi personali e, in generale, agisce per scopi estranei alla propria attività imprenditoriale, commerciale, artigianale o professionale.
</p>

      <hr className="separator" />

      <p className="textblockterms__h4">
        Ultima modifica: 18 marzo 2025
      </p>
    



    </div>
  );
}

export default TextBlockTerms;